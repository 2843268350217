import { useTranslation } from 'react-i18next';

import { Head } from 'components/common';

import Intro from 'components/reward-ad-plus/intro';
import NewValue from 'components/reward-ad-plus/new-value';
import NPAYPoint from 'components/reward-ad-plus/npay-point';
import RetentionPlus from 'components/reward-ad-plus/retention-plus';
import RevenuePlus from 'components/reward-ad-plus/revenue-plus';

import Layout from 'layout/index';

const RewardAdPlusPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      starterConfig={{
        title: t('rewardAdPlus.starter.title'),
        description: t('rewardAdPlus.starter.description'),
        contactHash: '#publishers',
      }}
    >
      <Head title="Reward AD Plus" description={t('rewardAdPlus.intro.description')} />
      <Intro />
      <NewValue />
      <RevenuePlus />
      <RetentionPlus />
      <NPAYPoint />
    </Layout>
  );
};

export default RewardAdPlusPage;
