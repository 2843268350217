import cn from 'classnames';
import { Intro } from 'components/common';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import { useTranslation } from 'react-i18next';
import { isEnglish, isKorean } from 'utils/lang-helper';

const RewardAdPlusIntro = () => {
  const { t, i18n } = useTranslation();

  const { backgroundImage, logoImage } = useStaticQuery<{
    backgroundImage: ImageDataLike;
    logoImage: Required<FileNode>;
  }>(
    graphql`
      query RewardAdPlusPageQuery {
        backgroundImage: file(absolutePath: { regex: "/reward-ad-plus/intro.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        logoImage: file(absolutePath: { regex: "/reward-ad-plus/intro-logo.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <Intro backgroundImage={backgroundImage}>
      <GatsbyImage
        objectFit="contain"
        className={cn('lg:w-[346px] lg:h-[48px]', 'w-[250px] h-[43px]')}
        image={logoImage.childImageSharp.gatsbyImageData}
        alt="logo.png"
      />
      <h1
        className={cn(
          'text-white font-bold',
          [
            'lg:px-0 lg:text-[50px] lg:leading-[124%] lg:mb-[8px] lg:tracking-normal lg:whitespace-normal',
            {
              'lg:max-w-[710px]': isKorean(i18n.language),
              'lg:max-w-[588px]': isEnglish(i18n.language),
            },
          ],
          'text-[32px] leading-[124%] mb-[10px] px-[20px] tracking-[-0.3px] whitespace-pre-wrap',
        )}
      >
        {t('rewardAdPlus.intro.title')}
      </h1>
      <p
        className={cn(
          'text-white tracking-[-0.3px] font-normal',
          'lg:text-[18px] lg:leading-[160%] lg:mb-[30px] lg:whitespace-pre-wrap',
          'text-[16px] leading-[160%] mb-[20px] px-[20px]',
        )}
      >
        {t('rewardAdPlus.intro.description')}
      </p>
      <Link
        className={cn(
          'text-white tracking-[-0.3px] bg-[#2685F4] px-[20px] rounded-[6px] hover:bg-[#2685F4]/80 focus:outline-none whitespace-nowrap',
          'lg:w-[122px] lg:py-[12px]',
          'w-[155px] py-[14px]',
        )}
        to="/contact#publishers"
      >
        {t('contact')}
      </Link>
    </Intro>
  );
};

export default RewardAdPlusIntro;
