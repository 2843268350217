import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const RevenuePlus = () => {
  const { t } = useTranslation();

  const { image } = useStaticQuery(
    graphql`
      query RewardAdPlusSection2Query {
        image: file(absolutePath: { regex: "/reward-ad-plus/revenue-plus.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <section className="flex justify-center items-center bg-[#FBFCFD]">
      <div
        className={cn(
          'flex justify-center items-center',
          'lg:max-w-[1200px] lg:flex-row lg:gap-[100px] lg:py-[80px] lg:px-0',
          'flex-col gap-[30px] py-[50px] px-[20px]',
        )}
      >
        <div className={cn('flex flex-col order-1', 'lg:min-w-[500px]')}>
          <h6
            className={cn(
              'text-[#2685F4] text-[16px] leading-normal font-semibold',
              'lg:text-left lg:text-[14px] leading-[21px] lg:mb-[4px]',
              'text-center text-[16px] leading-[24px] mb-[2px]',
            )}
          >
            Revenue Plus
          </h6>
          <h3
            className={cn(
              'text-[#212529] font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
              'lg:text-left lg:text-[36px]',
              'text-center text-[24px]',
            )}
          >
            {t(`rewardAdPlus.revenuePlus.title`)}
          </h3>
          <p
            className={cn(
              'text-[#808C99] font-normal tracking-[-0.3px] leading-[160%]',
              'lg:text-left lg:text-[18px]',
              'text-center text-[16px] mb-[10px]',
            )}
          >
            <span className="inline-block text-[#4B5563] mb-[4px]">
              {t(`rewardAdPlus.revenuePlus.description1.title`)}
            </span>
            <br />
            {t(`rewardAdPlus.revenuePlus.description1.details`)}
          </p>
          <p
            className={cn(
              'text-[#808C99] font-normal tracking-[-0.3px] leading-[160%]',
              'lg:text-left lg:text-[18px]',
              'text-center text-[16px]',
            )}
          >
            <span className="inline-block text-[#4B5563] mb-[4px]">
              {t(`rewardAdPlus.revenuePlus.description2.title`)}
            </span>
            <br />
            {t(`rewardAdPlus.revenuePlus.description2.details`)}
          </p>
        </div>
        <div className={cn('flex justify-center items-center w-full order-2')}>
          <GatsbyImage
            objectFit="contain"
            className={cn('lg:w-[600px] lg:h-[500px]', 'w-[320px] h-[298px]')}
            image={image.childImageSharp.gatsbyImageData}
            alt="revenue-plus.png"
          />
        </div>
      </div>
    </section>
  );
};

export default RevenuePlus;
